import React, { ReactElement } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import FeaturedImageStyles from './featured-image.module.scss';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { Avatar } from '@material-ui/core';
import { CommentCount } from 'gatsby-plugin-disqus';
import { ICategory } from '../../models/recipes.model';
import Chip from '../chip';

// FIXME: TRY NOT TO USE GATSBY STRUCTURE ON REUSABLE COMPONENTS
// TODO: CREATE COMPONENT MODEL

const FeaturedImage: React.FC<any> = ({ post, disqusConfig, hrefLink, homePage }): ReactElement => {
  const { t } = useTranslation();
  const featuredImageData = post;

  return (
    <div className={`${FeaturedImageStyles.section_featured} ${FeaturedImageStyles.is_featured_image}`}>
      {featuredImageData.localImage.childImageSharp.fluid.src && (
        <div
          className={FeaturedImageStyles.featured_image}
          style={{
            backgroundImage: `url(${
              featuredImageData.with_custom_thumbnail &&
              featuredImageData.custom_thumbnail &&
              featuredImageData.custom_thumbnail.childImageSharp &&
              featuredImageData.custom_thumbnail.childImageSharp.fluid.src
                ? featuredImageData.custom_thumbnail.childImageSharp.fluid.src
                : featuredImageData.localImage.childImageSharp.fluid.src
            })`,
          }}
        ></div>
      )}
      <div className={`${FeaturedImageStyles.featured_wrap} ${FeaturedImageStyles.flex}`}>
        <div className={FeaturedImageStyles.featured_content}>
          <div className={FeaturedImageStyles.tags_wrap}>
            {featuredImageData.categories.map((category: ICategory, index: number) => (
              <Chip
                path={category.path}
                id={category.id + '-' + Math.random()}
                label={category.name}
                key={index}
                clickable
              />
            ))}
            <Chip label={<time>{featuredImageData.source.date}</time>} id={'date-' + Math.random()} />
          </div>
          {hrefLink ? (
            <AniLink fade duration={1.2} to={hrefLink}>
              <h2>
                {featuredImageData.title}
                <span className={FeaturedImageStyles.featured_dot}></span>
              </h2>
              <h3>
                <span>{t('AUTHOR_BY')}</span> {featuredImageData.chef.alias}
                <Avatar
                  className={FeaturedImageStyles.avatar_featured}
                  src={featuredImageData.chef.avatar.childImageSharp.fluid.src}
                  aria-label={''}
                  alt={featuredImageData.chef.alias}
                />
              </h3>
            </AniLink>
          ) : (
            <AniLink fade duration={1.2} to={featuredImageData.chef.path}>
              <h2>
                {featuredImageData.title}
                <span className={FeaturedImageStyles.featured_dot}></span>
              </h2>
              <h3>
                <span>{t('AUTHOR_BY')}</span> {featuredImageData.chef.alias}
                <Avatar
                  className={FeaturedImageStyles.avatar_featured}
                  src={featuredImageData.chef.avatar.childImageSharp.fluid.src}
                  aria-label={''}
                  alt={featuredImageData.chef.alias}
                />
              </h3>
            </AniLink>
          )}
          {!homePage && (
            <div className={`${FeaturedImageStyles.item_meta} ${FeaturedImageStyles.info}`}>
              <span>
                <FontAwesomeIcon className={FeaturedImageStyles.icon} icon={faPlayCircle} size="1x" />
                {Math.round(featuredImageData.duration / 60)} {t('POST_DURATION_MIN')}
              </span>
              {featuredImageData.difficulty && (
                <span>
                  {t('RECIPE_LEVEL')} {featuredImageData.difficulty.name}
                </span>
              )}
              <div className={FeaturedImageStyles.item_meta}>
                <FontAwesomeIcon className={FeaturedImageStyles.icon} icon={faComment} size="1x" />
                <CommentCount config={disqusConfig} placeholder={'...'} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeaturedImage;
