import React, { ReactElement } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { ChipProps } from './chip.model';
import ChipStyles from './chip.module.scss';
import { StylesProvider, Chip } from '@material-ui/core';

const CustomChip: React.FC<ChipProps> = (props): ReactElement => {
  const chipElement = (
    <Chip
      className={props.clickable ? ChipStyles.label_chip : ChipStyles.label_chip_not_clickable}
      size={props.size || 'medium'}
      label={props.label}
      key={props.id + Math.random()}
      clickable={props.clickable}
    />
  );

  /**
   * In order to override material ui styles without using !important, we must wrap the content with StylesProvider
   * @see https://material-ui.com/guides/interoperability/#controlling-priority-4
   */
  return (
    <StylesProvider injectFirst>
      {props.clickable && props.path ? (
        <AniLink fade duration={1.5} to={props.path} key={props.id}>
          {chipElement}
        </AniLink>
      ) : (
        chipElement
      )}
    </StylesProvider>
  );
};

export default CustomChip;
